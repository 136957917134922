<template>
  <div>
    <h2 class="text-center headerOne">
      Beyaz Eşya Kurulum ve Arızıa Hizmetleri
    </h2>
    <carousel-3d>
      <slide :index="0">
        <img src="../../assets/beyazEsya/1.jpeg" alt="" />
      </slide>
      <slide :index="1">
        <img src="../../assets/beyazEsya/2.jpeg" alt="" />
      </slide>
      <slide :index="2">
        <img src="../../assets/beyazEsya/3.jpeg" alt="" />
      </slide>
      <slide :index="3">
        <img src="../../assets/beyazEsya/4.jpeg" alt="" />
      </slide>
      <slide :index="4">
        <img src="../../assets/beyazEsya/5.jpeg" alt="" />
      </slide>
    </carousel-3d>
  </div>
</template>
<script>
import { Carousel3d, Slide } from 'vue-carousel-3d';

export default {
  components: {
    Carousel3d,
    Slide,
  },
};
</script>

<style>
.headerOne {
  font-weight: bold;
  margin-top: 20px;
}
</style>
