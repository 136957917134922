<template>
  <div class="works">
    <top-section :isSliderOn="true" />

    <div id="elektrik-kornij-sensor-diafon">
      <elektrik />
    </div>
    <div id="insaat">
      <insaat />
    </div>
    <div id="klima">
      <klima />
    </div>
    <div id="uydu-merkezi">
      <uydu />
    </div>
    <div id="su-sohben">
      <su />
    </div>
    <div id="evAletleri">
      <evAletleri />
    </div>
    <div id="beyazEsya">
      <beyazEsya />
    </div>
    <footerr />
  </div>
</template>

<script>
import topSection from '@/components/home/TopSection.vue';
import footerr from '@/components/home/Footer.vue';
import elektrik from '@/components/works/Elektrik.vue';
import insaat from '@/components/works/Insaat.vue';
import klima from '@/components/works/Klima.vue';
import uydu from '@/components/works/Uydu.vue';
import su from '@/components/works/Su.vue';
import evAletleri from '@/components/works/EvAletleri.vue';
import beyazEsya from '@/components/works/BeyazEsya.vue';

export default {
  components: {
    topSection,
    elektrik,
    insaat,
    klima,
    uydu,
    su,
    evAletleri,
    beyazEsya,
    footerr,
  },

  mounted() {
    setTimeout(() => {
      if (this.$route.params.id !== '0') {
        document.getElementById(this.$route.params.id).scrollIntoView();
      }
    }, 1000);
  },
};
</script>

<style scoped></style>
