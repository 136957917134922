<template>
  <div>
    <h2 class="text-center headerOne">
      İnşaat Tadilat
    </h2>
    <carousel-3d>
      <slide :index="0">
        <img src="../../assets/insaat/1.jpeg" alt="" />
      </slide>
      <slide :index="1">
        <img src="../../assets/insaat/2.jpeg" alt="" />
      </slide>
      <slide :index="2">
        <img src="../../assets/insaat/3.jpeg" alt="" />
      </slide>
      <slide :index="3">
        <img src="../../assets/insaat/4.jpeg" alt="" />
      </slide>
      <slide :index="4">
        <img src="../../assets/insaat/5.jpeg" alt="" />
      </slide>
      <slide :index="5">
        <img src="../../assets/insaat/6.jpeg" alt="" />
      </slide>
      <slide :index="6">
        <img src="../../assets/insaat/7.jpeg" alt="" />
      </slide>
      <slide :index="7">
        <img src="../../assets/insaat/8.jpeg" alt="" />
      </slide>
      <slide :index="8">
        <img src="../../assets/insaat/9.jpeg" alt="" />
      </slide>
      <slide :index="9">
        <img src="../../assets/insaat/10.jpeg" alt="" />
      </slide>
      <slide :index="10">
        <img src="../../assets/insaat/11.jpeg" alt="" />
      </slide>
      <slide :index="11">
        <img src="../../assets/insaat/12.jpeg" alt="" />
      </slide>
    </carousel-3d>

    <div class="underline"></div>
  </div>
</template>
<script>
import { Carousel3d, Slide } from 'vue-carousel-3d';

export default {
  components: {
    Carousel3d,
    Slide,
  },
};
</script>
<style>
.headerOne {
  font-weight: bold;
  margin-top: 20px;
}
.underline {
  height: 15px;
  margin-top: 2px;
  background-color: #ed1c24;
}
</style>
