<template>
  <div>
    <h2 class="text-center headerOne">
      Klima Bakım Kurulum ve Tamiri
    </h2>
    <carousel-3d>
      <slide :index="0">
        <img src="../../assets/isler/1.jpeg" alt="" />
      </slide>
      <slide :index="1">
        <img src="../../assets/isler/2.jpeg" alt="" />
      </slide>
      <slide :index="2">
        <img src="../../assets/isler/3.jpeg" alt="" />
      </slide>
      <slide :index="3">
        <img src="../../assets/isler/4.jpeg" alt="" />
      </slide>
      <slide :index="4">
        <img src="../../assets/isler/5.jpeg" alt="" />
      </slide>
    </carousel-3d>
    <div class="underline"></div>
  </div>
</template>
<script>
import { Carousel3d, Slide } from 'vue-carousel-3d';

export default {
  components: {
    Carousel3d,
    Slide,
  },
};
</script>

<style>
.headerOne {
  font-weight: bold;
  margin-top: 20px;
}
.underline {
  height: 15px;
  margin-top: 2px;
  background-color: #ed1c24;
}
</style>
